import { getCookie, setCookie } from "cookies-next";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
const Cookie = () => {
  const [cookieModel, setCookieModel] = useState(false);
  useEffect(() => {
    if (getCookie("userentry") === true) {
      setCookieModel(false);
    } else {
      setCookieModel(true);
    }
  }, []);

  return (
    <div
      className={`rounded-lg fixed left-4 md:left-auto right-5 bottom-5 py-8 md:p-8 w-fit md:w-[35rem] bg-primary-black z-50 text-justify border border-solid border-white ${
        cookieModel ? "animate-fade-in-right" : "hidden"
      }`}
    >
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="md:mb-8">
          <Image
            alt="cookieImage"
            height={59}
            src="/assets/cookies.webp"
            width={59}
          />
        </div>
        <div className="w-4/5">
          <p className="text-white my-4">
            <span>
              We use cookies to improve user experience and analyze website
              traffic. By clicking “Accept“, you agree to our website&apos;s
              cookie use as described in our Cookie Policy.
            </span>
          </p>
          <div className="flex justify-end items-center">
            <Link passHref href="/privacy-policy">
              <a>
                <div className="text-primary-yellow pr-8 cursor-pointer">
                  <span>Privacy Policy</span>
                </div>
              </a>
            </Link>
            <div
              className="border border-solid border-primary-yellow rounded-lg py-2 px-8 text-white bg-primary-yellow flex justify-center items-center font-semibold cursor-pointer"
              role="presentation"
              onClick={() => {
                setCookie("userentry", true, {
                  maxAge: 365 * 24 * 60 * 60,
                }),
                  setCookieModel(false);
              }}
            >
              <span>Accept</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
