import Link from "next/link";

const YourConcept = ({ href, label }) => {
  return (
    <div className="ml-32 border border-solid border-primary-yellow rounded-2xl max-w-md pt-9 pb-5 pl-11 pr-16 h-auto">
      <p className="text-3xl font-medium text-white pb-3 text-nowrap">
        <span>Your Vision - Our Execution</span>
      </p>
      <p className="text-base font-normal text-white mb-8">
        <span>
          We deliver comprehensive IT solutions designed to drive your business
          to new heights.
        </span>
      </p>
      <Link href={href}>
        <a className="text-lg font-medium !bg-primary-yellow !text-white !py-4 px-7 rounded-2xl !border !border-solid !border-primary-yellow hover:!bg-transparent hover:!text-primary-yellow transition-all duration-500 ease-out hover:ease-in inline-block">
          <span>{label}</span>
        </a>
      </Link>
    </div>
  );
};

export default YourConcept;
