const Container = ({ large, small, className, children }) => (
  <div className={`${className}`}>
    <div
      className={`max-w-center-page-width mx-auto ${
        large ? "px-4 md:px-8 lg:px-16 " : ""
      } ${small ? "px-4 md:px-28 lg:px-40" : ""}
          `}
    >
      {children}
    </div>
  </div>
);

export default Container;
