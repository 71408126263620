import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LINK_OF_ALL_PAGES } from "const/common";
import {
  IMAGES_DATA,
  QUICK_LINK,
  SERVICES_LINK,
  TECH_PAGES_LINK,
} from "const/layout/footer";
import Image from "next/image";
import Link from "next/link";
import Container from "../common/Container";
import PrimaryButton from "../common/PrimaryButton";
import Behance from "../svg/Behance";
import Dribbble from "../svg/Dribbble";
import Github from "../svg/Github";
import Instagram from "../svg/Instagram";
import LinkedIn from "../svg/LinkedIn";
import Pinterest from "../svg/Pinterest";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <Container className="bg-primary-black" large>
      <div className="block xl:flex lg:gap-6 xl:gap-0 justify-between pt-8 xl:pt-16">
        <div className="w-full xl:w-7/12">
          <div className="border border-solid border-white/50 rounded-2xl my-4 p-8 xl:p-16 xl:mr-10 xl:my-0">
            <h2 className="font-medium text-white text-3xl text-left mb-8 md:text-6xl xl:text-5xl xl:mb-12">
              <span>Let’s Discuss your Ideas.</span>
            </h2>
            <PrimaryButton
              arrow={false}
              link={LINK_OF_ALL_PAGES.mainPages.contactUs}
              val="Contact us"
            />
          </div>
        </div>
        <div className="w-full xl:w-5/12">
          <div className="border border-solid border-white/50 rounded-2xl my-4 p-8 xl:p-16 xl:ml-10 xl:my-0 h-full flex flex-col gap-8 xl:gap-12 justify-between">
            <h2 className="font-medium text-white text-3xl text-left md:text-6xl xl:text-5xl">
              Join our Team
            </h2>
            <PrimaryButton
              arrow={false}
              link={LINK_OF_ALL_PAGES.mainPages.careers}
              val="Go to Career"
              className="ml-0 mr-auto"
            />
          </div>
        </div>
      </div>
      <div className="py-4 md:py-14 grid grid-row-4 grid-cols-2 md:grid-row-3 md:grid-cols-[182px,212px,182px] xl:grid-cols-[290px,207px,250px,135px,188px] xl:grid-rows-none gap-x-10 xl:gap-x-0 md:justify-between">
        <div className="fs-0 text-center pt-10 pb-14 md:pt-0 md:pb-12 xl:pb-0 md:text-start col-start-1 col-end-3 xl:col-start-1 xl:col-end-2 xl:row-start-1">
          <Image
            alt="logo"
            height={40}
            objectFit="contain"
            src="/assets/common/unt-logo.svg"
            title=" ultroNeous Technologies - Web and Mobile App Development Company"
            width={230}
          />
        </div>
        <div className="text-center md:text-start py-14 md:pb-0 xl:pt-0 col-start-1 col-end-3 row-start-4 row-end-5 xl:col-start-1 xl:col-end-2 xl:row-start-2 xl:row-end-2">
          <h5 className="text-white text-xl font-medium pb-5 md:pb-5 xl:pb-2">
            Our Offices
          </h5>
          <div className="flex flex-col md:flex-row xl:flex-col md:gap-12 xl:gap-0">
            <div>
              <p className="font-medium text-base text-white mb-1">INDIA</p>
              <p className="font-medium text-sm xl:text-base text-white/60 pb-6 md:pb-0 xl:pb-5 w-60 mx-auto xl:mr-auto xl:ml-0">
                B 401-402, Shilp Corporate Park, Rajpath Rangoli Rd, Thaltej,
                Ahmedabad, Gujarat 380054
              </p>
            </div>
            <div>
              <p className="font-medium text-base text-white mb-1">CANADA</p>
              <p className="font-medium text-sm xl:text-base text-white/60 w-56 mx-auto xl:mr-auto xl:ml-0">
                1932 50 Ave SW,Calgary, AB T2T 2W2, Canada
              </p>
            </div>
          </div>
        </div>
        <div className="row-start-2 row-end-3 md:col-start-1 md:col-end-2 xl:col-start-2 xl:row-start-1 xl:row-end-3">
          <h5 className="text-white mb-4 md:mb-2 text-xl font-medium">
            Hire a Team
          </h5>
          {TECH_PAGES_LINK.map((value) => (
            <Link href={value.link} key={value.id}>
              <a className="text-white/60 block text-base md:text-sm xl:text-base mb-4 md:mb-2 hover:transition-all hover:duration-500 hover:text-primary-yellow hover:md:pl-2">
                {value.techPageName}
              </a>
            </Link>
          ))}
        </div>
        <div className="xl:col-start-3 xl:row-start-1 xl:row-end-3 row-start-2 row-end-3">
          <h5 className="text-white mb-4 md:mb-2 text-xl font-medium">
            Our Services
          </h5>
          {SERVICES_LINK.map((value) => (
            <Link href={value.link} key={value.id}>
              <a className="text-white/60 block text-base md:text-sm xl:text-base mb-4 md:mb-2 hover:transition-all hover:duration-500 hover:text-primary-yellow hover:md:ml-2">
                {value.pageName}
              </a>
            </Link>
          ))}
        </div>
        <div className="xl:col-start-4 xl:row-start-1 xl:row-end-3 hidden xl:block">
          <h5 className="text-white mb-2 text-xl font-medium">Quick Links</h5>
          {QUICK_LINK.map((value) => (
            <Link href={value.link} key={value.id}>
              <a className="text-white/60 text-base hover:transition-all hover:duration-500 hover:text-primary-yellow mt-2 block hover:ml-2">
                {value.pageName}
              </a>
            </Link>
          ))}
        </div>
        <div className="col-start-1 col-end-3 row-start-3 row-end-4 pt-10 md:pt-0 md:col-start-3 md:col-end-4 md:row-start-2 md:row-end-3 xl:col-start-5 xl:col-end-6 xl:row-start-1 xl:row-end-3">
          <h5 className="text-white mb-5 xl:mb-3 text-xl font-medium text-center md:text-start">
            Keep in touch
          </h5>
          <p
            className="flex pb-5 xl:pb-3 justify-center md:justify-start"
            title="Call us"
          >
            <span className="mr-1 flex">
              <Image
                alt="india"
                height={16}
                objectFit="contain"
                src="/assets/footer/indian-flag.webp"
                width={16}
              />
            </span>
            <span>
              <a
                href="tel:+91-78748-13131"
                className="text-white/60 text-base hover:transition-all hover:duration-500 hover:text-primary-yellow hover:ml-1"
              >
                +91-78748-13131
              </a>
            </span>
          </p>
          <p
            className="flex justify-center md:justify-start text-white/60 pb-5 xl:pb-4"
            title="Send Email"
          >
            <span className="me-2 flex items-center">
              <FontAwesomeIcon icon={faEnvelope} width="1rem" />
            </span>
            <span>
              <a
                href="mailto:hello@ultroneous.com"
                className="text-white/60 text-base hover:transition-all hover:duration-500 hover:text-primary-yellow hover:ml-1"
              >
                hello@ultroneous.com
              </a>
            </span>
          </p>
          <div className="fs-0 text-center md:text-start">
            <Link href="https://dribbble.com/ultroneous">
              <a className="p-2 border border-solid text-white/60 mr-3 inline-block hover:bg-primary-yellow rounded-md">
                <Dribbble height={17} width={17} fill="#cccccc" />
              </a>
            </Link>
            <Link href="https://www.behance.net/ultroneous">
              <a className="p-2 border border-solid text-white/60 mr-3 inline-block hover:bg-primary-yellow rounded-md">
                <Behance height={17} width={17} fill="#cccccc" />
              </a>
            </Link>
            <Link href="https://www.instagram.com/ultroneous.technologies/">
              <a className="p-2 border border-solid text-white/60 mr-3 inline-block hover:bg-primary-yellow rounded-md">
                <Instagram height={17} width={17} fill="#cccccc" />
              </a>
            </Link>
            <Link href="https://www.behance.net/ultroneous">
              <a className="p-2 border border-solid text-white/60 mr-3 md:mr-0 inline-block hover:bg-primary-yellow rounded-md">
                <LinkedIn height={17} width={17} fill="#cccccc" />
              </a>
            </Link>
            <Link href="https://github.com/ultroneous">
              <a className="p-2 border border-solid text-white/60 mr-3 md:mr-3 inline-block hover:bg-primary-yellow mt-4 rounded-md">
                <Github height={17} width={17} fill="#cccccc" />
              </a>
            </Link>
            <Link href="https://in.pinterest.com/ultroneoustechnologies/">
              <a className="p-2 border border-solid text-white/60 inline-block hover:bg-primary-yellow mt-4 rounded-md">
                <Pinterest height={17} width={17} fill="#cccccc" />
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row flex-wrap justify-between items-center pb-5 md:pb-12">
        {IMAGES_DATA.map((value) => (
          <div
            className="flex justify-center items-center pb-10 md:pb-0"
            key={value.id}
          >
            <span className="fs-0 md:w-1/2 xl:w-fit ">
              <Image
                alt={value.alt}
                height={value.height}
                src={value.src}
                width={value.width}
                title={value.alt}
              />
            </span>
            <span className="fs-0 ml-7 md:ml-3 xl:ml-7 mr-2">
              <Image
                alt="star"
                height={16}
                src="/assets/footer/star.svg"
                width={16}
              />
            </span>
            <span className="text-base md:text-xs xl:text-base font-medium text-white/60">
              {value.rating}
            </span>
          </div>
        ))}
      </div>
      <hr className="bg-white/5 border-0 h-px" />
      <p className="text-white text-xs font-medium py-6 text-center">
        © {currentYear} ultroNeous. All Rights Reserved.
      </p>
    </Container>
  );
}

export default Footer;
