const Dribbble = ({ fill, height, width, className }) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={height}
      viewBox="0 0 30 29"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.6126 -2.98348e-08C6.54962 -1.3357e-08 0.0136716 6.31057 0.0136712 14.0955C0.0136708 21.8805 6.54962 28.1911 14.6126 28.1911C22.6756 28.1911 29.2116 21.8805 29.2116 14.0955C29.2116 6.31057 22.6756 -4.63126e-08 14.6126 -2.98348e-08ZM14.6126 2.04244C17.793 2.04244 20.696 3.19123 22.9004 5.08144C21.2807 7.14432 18.82 8.51793 16.3543 9.44753C14.9584 6.99303 13.3941 4.63146 11.6717 2.37862C12.635 2.15443 13.6224 2.04158 14.6133 2.04244L14.6126 2.04244ZM9.30152 3.18418C10.9293 5.53531 12.506 7.71519 13.9462 10.1826C10.3037 11.0939 6.18756 11.6408 2.38527 11.65C3.18675 7.89491 5.79923 4.77697 9.30152 3.18488L9.30152 3.18418ZM24.2851 6.47548C26.089 8.60388 27.0814 11.2657 27.0962 14.0159C24.2085 13.4662 21.3369 13.3224 18.4127 13.6071C18.0842 12.8157 17.6857 12.0637 17.3039 11.2532C19.8244 10.2714 22.5194 8.59122 24.2851 6.47478L24.2851 6.47548ZM14.9601 12.0474C15.2725 12.6888 15.6345 13.3696 15.9747 14.0652C11.9089 15.7962 7.58395 18.1092 5.33936 22.1645C3.18457 19.8622 2.03621 16.8441 2.1349 13.7424C6.48903 13.7227 10.7629 13.1969 14.9601 12.0474ZM21.5573 15.3338C23.374 15.3342 25.1832 15.5592 26.9407 16.0034C26.6697 17.6358 26.0538 19.1969 25.1311 20.5897C24.2085 21.9824 22.999 23.177 21.5778 24.0991C20.9077 21.1567 20.269 18.3411 19.1397 15.5023C19.9401 15.3897 20.7478 15.3331 21.5566 15.3331L21.5573 15.3338ZM27.0057 15.5523C26.9969 15.6214 26.9889 15.689 26.9786 15.7567C26.9889 15.6883 26.9976 15.6207 27.0057 15.5523ZM16.8302 16.0527C17.9806 18.9232 18.8835 22.1018 19.4894 25.195C17.9472 25.8269 16.2887 26.1514 14.6133 26.1493C11.838 26.1531 9.14112 25.26 6.95255 23.6121C9.21319 20.0713 12.7147 17.3918 16.8295 16.0534L16.8302 16.0527Z" />
    </svg>
  );
};

export default Dribbble;
