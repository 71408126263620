import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";

const Accordion = ({ title, children, pageLink,isOpen,onToggle }) => {
  return (
    <div className="pb-5">
      <div className="text-white">
        <p className="flex justify-between items-center">
          {pageLink ? (
            <Link href={pageLink}>
              <a
                className={`text-base font-medium ${
                  isOpen ? "text-primary-yellow" : "text-white"
                }`}
              >
                {title}
              </a>
            </Link>
          ) : (
            <span
              className={`text-base font-medium ${
                isOpen ? "text-primary-yellow" : "text-white"
              }`}
            >
              {title}
            </span>
          )}
          <span onClick={onToggle}>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${
                isOpen ? "rotate-180 text-primary-yellow" : "text-white"
              } text-base`}
              width={12}
              height={12}
            />
          </span>
        </p>
      </div>
      {isOpen && <div className="pt-5 pl-2.5">{children}</div>}
    </div>
  );
};

export default Accordion;
