const Behance = ({ fill, className, width, height }) => {
  return (
    <svg
      fill={fill}
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 21"
    >
      <path d="M21.0356 1.332L29.0751 1.332L29.0751 3.27383L21.0356 3.27383L21.0356 1.332ZM12.98 9.01507C14.7572 8.16853 15.6881 6.88066 15.6881 4.89069C15.6881 0.954867 12.7463 -2.60487e-08 9.34916 -1.91062e-08L0 0L-1.01205e-06 19.7553L9.6111 19.7553C13.2138 19.7553 16.5948 18.0301 16.5948 14.0181C16.5948 11.5386 15.4181 9.70514 12.98 9.01507ZM4.36026 3.37012L8.45052 3.37012C10.0262 3.37012 11.4406 3.80743 11.4406 5.6289C11.4406 7.30594 10.3405 7.97996 8.78096 7.97996L4.36026 7.97996L4.36026 3.37012ZM9.01469 16.4013L4.35623 16.4013L4.35623 10.9609L9.10738 10.9609C11.0256 10.9609 12.2385 11.7593 12.2385 13.7814C12.2385 15.7714 10.7918 16.4013 9.01469 16.4013ZM32.2183 12.8105C32.2183 8.57776 29.732 5.05117 25.2347 5.05117C20.8623 5.05117 17.8883 8.32901 17.8883 12.6259C17.8883 17.0793 20.7052 20.1365 25.2347 20.1365C28.6641 20.1365 30.8845 18.5999 31.9524 15.322L28.4747 15.322C28.0958 16.5457 26.5565 17.1876 25.3596 17.1876C23.0465 17.1876 21.8376 15.8396 21.8376 13.5487L32.1901 13.5487C32.2022 13.312 32.2183 13.0632 32.2183 12.8105ZM21.8376 11.0692C21.9625 9.18759 23.2238 8.01206 25.1098 8.01206C27.0924 8.01206 28.0838 9.17154 28.257 11.0692L21.8376 11.0692Z" />
    </svg>
  );
};

export default Behance;
