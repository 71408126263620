import { LINK_OF_ALL_PAGES } from "const/common";

export const QUICK_LINK = [
  {
    id: 1,
    link: LINK_OF_ALL_PAGES.mainPages.aboutUltroneous,
    pageName: "About ultroNeous",
  },
  {
    id: 2,
    link: LINK_OF_ALL_PAGES.mainPages.careers,
    pageName: "Career",
  },
  {
    id: 3,
    link: LINK_OF_ALL_PAGES.mainPages.contactUs,
    pageName: "Contact us",
  },
  {
    id: 4,
    link: LINK_OF_ALL_PAGES.mainPages.blog,
    pageName: "Blog",
  },
  {
    id: 5,
    link: LINK_OF_ALL_PAGES.mainPages.businessModel,
    pageName: "Business Model",
  },
  {
    id: 6,
    link: LINK_OF_ALL_PAGES.mainPages.portfolio,
    pageName: "Portfolio",
  },
  { id: 7, link: "/privacy-policy", pageName: "Privacy Policy" },
];
export const SERVICES_LINK = [
  {
    id: 1,
    link: LINK_OF_ALL_PAGES.services.customSoftwareDevelopment,
    pageName: "Custom Software Development",
  },
  {
    id: 2,
    link: LINK_OF_ALL_PAGES.mainPages.dataSolution,
    pageName: "Web Scraping",
  },
  {
    id: 3,
    link: LINK_OF_ALL_PAGES.services.staffAugmentation,
    pageName: "IT Staff Augmentation",
  },
  {
    id: 4,
    link: LINK_OF_ALL_PAGES.services.uiUXDesignServices,
    pageName: "UI/UX Design",
  },
  {
    id: 5,
    link: LINK_OF_ALL_PAGES.services.digitalMarketing,
    pageName: "Digital Marketing",
  },
  {
    id: 6,
    link: LINK_OF_ALL_PAGES.services.itConsultationAssistance,
    pageName: "IT Consulting",
  },
  {
    id: 7,
    link: LINK_OF_ALL_PAGES.services.webApplicationDevelopmentServices,
    pageName: "Web Application Development",
  },
  {
    id: 8,
    link: LINK_OF_ALL_PAGES.services.mobileApplicationDevelopmentServices,
    pageName: "Mobile Application Development",
  },
  {
    id: 9,
    link: LINK_OF_ALL_PAGES.services.cloudAndDevopsServices,
    pageName: "Cloud & Devops Services",
  },
  {
    id: 10,
    link: LINK_OF_ALL_PAGES.services.enterpriseSoftwareDevelopmentServices,
    pageName: "Enterprise Software Development",
  },
];
export const TECH_PAGES_LINK = [
  {
    id: 1,
    link: LINK_OF_ALL_PAGES.services.hireNextJSDeveloper,
    techPageName: "NextJS Developer",
  },
  {
    id: 2,
    link: LINK_OF_ALL_PAGES.services.hireVueJSDeveloper,
    techPageName: "VueJS Developer",
  },
  {
    id: 3,
    link: LINK_OF_ALL_PAGES.services.hireAngularDeveloper,
    techPageName: "AngularJS Developer",
  },
  {
    id: 4,
    link: LINK_OF_ALL_PAGES.services.hireReactJSDeveloper,
    techPageName: "ReactJS Developer",
  },
  {
    id: 5,
    link: LINK_OF_ALL_PAGES.services.hireNodeJSDeveloper,
    techPageName: "NodeJS Developer",
  },
  {
    id: 6,
    link: LINK_OF_ALL_PAGES.services.hireKotlinDeveloper,
    techPageName: "Kotlin Developer",
  },
  {
    id: 7,
    link: LINK_OF_ALL_PAGES.services.hirePythonDeveloper,
    techPageName: "Python Developer",
  },
  {
    id: 8,
    link: LINK_OF_ALL_PAGES.services.hireMeanDeveloper,
    techPageName: "MEAN Stack Developer",
  },
  {
    id: 9,
    link: LINK_OF_ALL_PAGES.services.hireRoRDeveloper,
    techPageName: "Ruby on Rails Developer",
  },
  {
    id: 10,
    link: LINK_OF_ALL_PAGES.services.hireFlutterDeveloper,
    techPageName: "Flutter Developer",
  },
];
export const IMAGES_DATA = [
  {
    id: 1,
    src: "/assets/footer/Clutch.webp",
    alt: "ultroNeous - at clutch",
    rating: "5.0",
    width: 85,
    height: 24,
  },
  {
    id: 2,
    src: "/assets/footer/google.webp",
    alt: "ultroNeous - at google",
    rating: 4.6,
    width: 73,
    height: 24,
  },
  {
    id: 3,
    src: "/assets/footer/topdeveloper.webp",
    alt: "ultroNeous - at topdevelopers",
    rating: "5.0",
    width: 139,
    height: 24,
  },
  {
    id: 4,
    src: "/assets/footer/designrush.webp",
    alt: "ultroNeous - at designrush",
    rating: 4.3,
    width: 106,
    height: 24,
  },
];
