const ROAD_SIDE_SERVICE_ASSISTANCE_MOBILE_APP =
  "Roadside Service Assistance Mobile App";
const KNOW_YOUR_CUSTOMER_APP = "Know Your Customer (KYC) App";

export const button_value = "Get a Quote";
export const IS_MOBILE = (value) => window.innerWidth < value;
export const Is_TAB = (value) => window.innerWidth < value;
export const API_URL = "https://api.ultroneous.com/";
export const emailValidationRegex =
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const LINK_OF_ALL_PAGES = {
  mainPages: {
    aboutUltroneous: "/about-ultroneous",
    careers: "/careers",
    contactUs: "/contact-us",
    home: "/",
    expertise: "/technologies",
    portfolio: "/portfolio",
    dataSolution: "/web-scraping-services",
    blog: "/blog",
    businessModel: "/business-model",
  },
  services: {
    servicesMain: "/services",
    customSoftwareDevelopment: "/custom-software-development",
    staffAugmentation: "/it-staff-augmentation-services",
    digitalMarketing: "/digital-marketing-services",
    itConsultationAssistance: "/it-consulting-services",
    // 19 pages links
    // web
    hireReactJSDeveloper: "/hire-reactjs-developers",
    hireAngularDeveloper: "/hire-angularjs-developers",
    hireNextJSDeveloper: "/hire-nextjs-developer",
    hireVueJSDeveloper: "/hire-vuejs-developers",
    // backend
    hireNodeJSDeveloper: "/hire-nodejs-developers",
    hireRoRDeveloper: "/hire-ruby-on-rails-developers",
    hirePythonDeveloper: "/hire-python-developers",
    hireGolangDeveloper: "/hire-golang-developers",
    hireLaravelDeveloper: "/laravel-development",
    // mobile
    hireKotlinDeveloper: "/hire-kotlin-developers",
    hireFlutterDeveloper: "/hire-flutter-developers",
    hireiOSDeveloper: "/hire-ios-developers",
    hireSwiftDeveloper: "/hire-swift-developers",
    hireReactNativeDeveloper: "/hire-react-native-developers",
    hireAndroidDeveloper: "/hire-android-developers",
    // fullstack
    hireMernDeveloper: "/hire-mern-stack-developers",
    hireMeanDeveloper: "/hire-mean-stack-developers",
    // open source
    hireShopifyDeveloper: "/hire-shopify-developers",
    //Services-inner-pages
    webApplicationDevelopmentServices: "/web-application-development-services",
    mobileApplicationDevelopmentServices:
      "/mobile-application-development-services",
    uiUXDesignServices: "/ui-ux-design-services",
    cloudAndDevopsServices: "/cloud-and-devops-services",
    enterpriseSoftwareDevelopmentServices:
      "/enterprise-software-development-services",
    // data-Solution inner page
    enterPriseWebScrapingService: "/enterprise-web-scraping-services",
    mobileAppScrapingServices: "/mobile-app-scraping-services",
    webScrapingApi: "/web-scraping-api",
  },
};
export const COUNTER_DATA = [
  { id: 1, end: 50, sign: "+", title: "Projects Completed" },
  { id: 2, end: 97, sign: "%", title: "Positive Feedback" },
  { id: 3, end: 15, sign: "+", title: "Countries Served" },
  { id: 4, sign: "+", end: 32, title: "Clientele" },
  { id: 5, end: 30, sign: "+", title: "Experts" },
];
export const INDUSTRIES_SERVE_DATA = [
  {
    title: "Travel",
  },
  {
    title: "Education",
  },
  {
    title: "Healthcare",
  },
  {
    title: "Beauty ",
  },
  {
    title: "Real Estate",
  },
];
export const INDUSTRIES_SLIDER_DATA = [
  {
    color: "#E0E4FF",
    heading: "Bueno Travel App",
    details:
      "This app simplifies the process of booking flight tickets and saves users valuable time. Its design delights the users with the look and feel of an actual ticket, and its interface and easy navigation make the booking experience enjoyable.",
    image: "/assets/common/travel.webp",
  },
  {
    color: "#EAF4EC",
    heading: "Cube Corner - A Educational Website",
    details:
      " This educational dashboard contains valuable information, including training days, earned points, completed courses, and more. Users can also view their email correspondence and track their progress through an interactive graph.",
    image: "/assets/common/eductaion.webp",
  },
  {
    color: "#FBF0F2",
    heading: "Pet Care- a pet caring center",
    details:
      " This pet caring institute's website is a convenient solution for busy pet owners. Training and daycare sessions can be scheduled with a few clicks. It also showcases videos demonstrating how the pets are trained and cared for.",
    image: "/assets/common/healthcare.webp",
  },
  {
    color: "#F1F5ED",
    heading: "Impression- Salon Booking App",
    details:
      " This styling app provides users with the location of the nearest barbershops with genuine reviews and enables them to book the slot as per their needs with utmost convenience. Also, other shop owners can be part of this marketplace.",
    image: "/assets/common/beauty.webp",
  },
  {
    color: "#E5E9EC",
    heading: "Feel Home- Real Estate website",
    details:
      "This website allows users to rent apartments, villas, and bungalows. This way, they get the best of both worlds: affordability and the comfort of home-cooked food. Users can also easily search for their preferred location and travel dates.",
    image: "/assets/common/realestate.webp",
  },
];
export const CHECKOUT_OUR_RECENT_WORK_DATA = [
  {
    src: "/assets/common/gbg-mockup.webp",
    alt: "Ecommerce Marketplace",
    title: "Ecommerce Marketplace for Cyclists",
    imagTitle: "Ecommerce Marketplace for Cyclists",
    about:
      "A bicycle marketplace system for both sellers & buyers. Sellers can post their gear for sale along with bike exchange & cycling accessories, whereas buyers can make orders in bulk as well as in single.",
    link: "/portfolio/go-cyclists-marketplace-app-case-study",
    description:
      "I am truly impressed with the outstanding results delivered by your company in creating and developing our e-commerce marketplace. A deep understanding of our vision, The attention to detail of UX & aesthetics, efficiency of platform to handle high traffic volumes made it stand out from competitors. Thanks ultroNeous, I look forward to continued collaboration with your team in future projects.",
    company: "Go Cyclists",
  },
  {
    src: "/assets/common/roaddy-mockup.webp",
    alt: ROAD_SIDE_SERVICE_ASSISTANCE_MOBILE_APP,
    title: ROAD_SIDE_SERVICE_ASSISTANCE_MOBILE_APP,
    imagTitle: ROAD_SIDE_SERVICE_ASSISTANCE_MOBILE_APP,
    about:
      "Whenever people need their automobiles to be transported to the nearest repair shop, this app comes in handy. It is a mobile app for road assistance. So whenever users feel stuck in the middle of nowhere, Roaddy is there to provide professional help quickly.",
    link: "/portfolio/road-assistance-mobile-app-case-study",
    description:
      'Our search for a reliable web and <a href="https://ultroneous.com/mobile-application-development-services"> app development company</a>  finally ended when we reached out to ultroNeous. They understood our idea and shared valuable market insights; it was like they were always part of our team. The whole team did an outstanding job on our app development project with their great attitude and completed the project on time.',
    company: " Roaddy Team",
  },
  {
    src: "/assets/common/kyc-mockup.webp",
    alt: KNOW_YOUR_CUSTOMER_APP,
    title: KNOW_YOUR_CUSTOMER_APP,
    imagTitle: KNOW_YOUR_CUSTOMER_APP,
    about:
      "To assist in communicating information and warnings along with saving time to deal with different types of clients in a simple manner, here is a collaborative review platform open to every service or goods provider across a wide range of professions.",
    link: "/portfolio/know-your-customer-app-case-study",
    description:
      "When I look for software development outsourcing services, I don't only look for what kind of services they provide but also how the whole team would approach my idea. And this team stayed true to their promise; They implemented all the features I specified, and the final product met and exceeded my expectations.",
    company: "KYC Team",
  },
];

