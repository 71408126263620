import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { memo } from "react";

function PrimaryButton({
  val,
  arrow,
  link ,
  customStyle,
  className,
}) {
  return (
    <Link href={link}>
      <a
        className={`border border-solid border-primary-yellow rounded-lg text-base md:text-sm xl:text-lg font-medium outline-none transition-all duration-500 ease-out hover:ease-in px-10 py-4 mx-auto inline-block min-w-56 md:min-w-48 xl:min-w-64 text-center
        ${
          customStyle
            ? `bg-transparent text-primary-yellow hover:bg-primary-yellow hover:text-white`
            : `bg-primary-yellow text-white hover:bg-transparent hover:text-primary-yellow`
        } ${className}`}
        href={link}
      >
        <span>{val}</span>
        {arrow ? (
          <FontAwesomeIcon
            height={20}
            icon={faArrowRightLong}
            width={40}
            className="inline mb-1"
          />
        ) : undefined}
      </a>
    </Link>
  );
}

export default memo(PrimaryButton);
